import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import { FileModel } from "@dwo/shared/dist/models/fileModel";
import { JobSitePhotoModel } from "@dwo/shared/dist/models/jobSitePhotoModel";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { employeeService } from "@dwo/shared/dist/services/employeeService";
import { fileService } from "@dwo/shared/dist/services/fileService";
import { jobSitePhotoService } from "@dwo/shared/dist/services/jobSitePhotoService";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "app/store";
import { error } from "features/error/errorSlice";
import { getJobById } from "features/jobs/jobsSlice";
import {
  hide as hideLoader,
  show as showLoader,
} from "features/loader/loaderSlice";
import { DEFAULT_LIMIT, FileData } from "utils/sharedUtils";

interface jobExpensesState {
  count: number;
  expenseFile?: FileModel;
  isLoading: boolean;
  isLoadingDeleting: boolean;
  isLoadingFile: boolean;
  jobExpense?: JobSitePhotoModel;
  jobExpenses: JobSitePhotoModel[];
  limit?: number;
  offset?: number;
}

const initialState: jobExpensesState = {
  count: 0,
  expenseFile: undefined,
  isLoading: false,
  isLoadingDeleting: false,
  isLoadingFile: false,
  jobExpense: undefined,
  jobExpenses: [],
  limit: DEFAULT_LIMIT,
  offset: 0,
};

export const jobExpensesSlice = createSlice({
  name: "jobExpenses",
  initialState,
  reducers: {
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    setExpenseFile: (state, action: PayloadAction<FileModel | undefined>) => {
      state.expenseFile = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsLoadingDeleting: (state, action: PayloadAction<boolean>) => {
      state.isLoadingDeleting = action.payload;
    },
    setIsLoadingFile: (state, action: PayloadAction<boolean>) => {
      state.isLoadingFile = action.payload;
    },
    setJobExpense: (state, action: PayloadAction<JobSitePhotoModel>) => {
      state.jobExpense = action.payload;
    },
    setJobExpenses: (state, action: PayloadAction<JobSitePhotoModel[]>) => {
      state.jobExpenses = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
  },
});

export const {
  setCount,
  setExpenseFile,
  setIsLoading,
  setIsLoadingDeleting,
  setIsLoadingFile,
  setJobExpense,
  setJobExpenses,
  setLimit,
  setOffset,
} = jobExpensesSlice.actions;

export const getAllJobExpenses = (options?: ServiceOptions): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch(setIsLoading(true));
    const { data: expensesData } = await jobSitePhotoService.getAll(options);

    const employeeIds: number[] = expensesData.map(
      (expense: JobSitePhotoModel) => expense.createdById as number,
    );

    const { data: employeeData } = await employeeService.getProfiles(
      employeeIds,
    );

    const expensesDataUpdated = expensesData.map(
      (expense: JobSitePhotoModel) => {
        expense.uploadedBy = employeeData.find(
          (employee: EmployeeModel) =>
            (employee.id as number) ===
            (expense.createdBy?.employeeId as number),
        )?.fullName;

        return expense;
      },
    );

    dispatch(setJobExpenses(expensesDataUpdated));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not retrieve Job Expenses. Please try again.",
          message: err.message,
        },
        () => dispatch(getAllJobExpenses(options)),
      ),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getJobExpenseFile = (
  fileId: number,
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setExpenseFile(undefined));
    dispatch(setIsLoadingFile(true));
    const { data } = await fileService.getById(fileId, options);
    dispatch(setExpenseFile(data));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not retrieve Job Expense File. Please try again.",
          message: err.message,
        },
        () => dispatch(getJobExpenseFile(fileId, options)),
      ),
    );
  } finally {
    dispatch(setIsLoadingFile(false));
  }
};

export const createJobExpense = (
  jobExpense: JobSitePhotoModel,
  fileData?: FileData,
  options?: ServiceOptions,
  jobId?: number,
  getJobByIdOptions?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(showLoader());

    if (fileData) {
      const { data: fileResponseData } = await fileService.create({
        fileName: fileData.fileName,
        type: "job_site_photo",
      });

      await fileService.uploadFileToS3(
        fileData.file?.type as string,
        fileData.src as Blob,
        fileResponseData.uploadUrl as string,
      );

      const fileId =
        typeof fileResponseData.id === "string"
          ? parseInt(fileResponseData.id, 10)
          : fileResponseData.id;

      const { data } = await jobSitePhotoService.create({
        ...jobExpense,
        fileId: fileId,
      });
      dispatch(setJobExpense(data));
      dispatch(getAllJobExpenses(options));

      if (jobId && getJobByIdOptions) {
        dispatch(getJobById(jobId, getJobByIdOptions));
      }
      return;
    }

    const { data } = await jobSitePhotoService.create(jobExpense);
    dispatch(setJobExpense(data));
    dispatch(getAllJobExpenses(options));

    if (jobId && getJobByIdOptions) {
      dispatch(getJobById(jobId, getJobByIdOptions));
    }
  } catch (err) {
    dispatch(
      error(
        {
          title: "Something went wrong",
          message: err.message,
        },
        () => dispatch(createJobExpense(jobExpense, fileData, options)),
      ),
    );
  } finally {
    dispatch(hideLoader());
  }
};

export const deleteJobExpense = (
  id: number | string,
  options?: ServiceOptions,
  jobId?: number,
  getJobByIdOptions?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoadingDeleting(true));
    await jobSitePhotoService.delete(id);
    dispatch(getAllJobExpenses(options));
    if (jobId && getJobByIdOptions) {
      dispatch(getJobById(jobId, getJobByIdOptions));
    }
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not delete the Expense. Please try again.",
          message: err.message,
        },
        () => dispatch(deleteJobExpense(id, options)),
      ),
    );
  } finally {
    dispatch(setIsLoadingDeleting(false));
  }
};

export const selectExpenseFile = (state: RootState) =>
  state.jobExpenses.expenseFile;
export const selectIsLoading = (state: RootState) =>
  state.jobExpenses.isLoading;
export const selectIsLoadingDeleting = (state: RootState) =>
  state.jobExpenses.isLoadingDeleting;
export const selectIsLoadingFile = (state: RootState) =>
  state.jobExpenses.isLoadingFile;
export const selectJobExpense = (state: RootState) =>
  state.jobExpenses.jobExpense;
export const selectJobExpenses = (state: RootState) =>
  state.jobExpenses.jobExpenses;

export default jobExpensesSlice.reducer;
