import {
  Box,
  Button,
  createStyles,
  Divider,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { GlobalModal } from "components/globalModal/GlobalModal";
import { ExpenseForm } from "components/jobDetails/summary/ExpenseForm";
import { createJobExpense } from "features/jobs/jobExpenses/jobExpensesSlice";
import { selectCurrentJob } from "features/jobs/jobsSlice";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { initialJobDetailsQuery } from "utils/jobDetailsUtils";
import { FileData } from "utils/sharedUtils";
import { v4 as uuid } from "uuid";

interface AddExpensesModalProps {
  isOpen: boolean;
  onToggle: VoidFunction;
}

type ExpenseFormValues = {
  description: string;
  amount: number | string;
  type: string;
};

export type ExpenseValues = {
  file: FileData | undefined;
  values: ExpenseFormValues;
  isReBillable: boolean;
  errors?: boolean;
  id: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      color: theme.palette.primary.main,
      marginBottom: "8px",
    },
    addExpensesButton: {
      width: "100%",
      marginTop: "13.5px",
    },
    addNewExpensesButton: {
      width: "100%",
    },
    divider: {
      width: "100%",
      marginTop: "13.5px",
    },
  }),
);

export function AddExpensesModal({ isOpen, onToggle }: AddExpensesModalProps) {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const jobId = parseInt(id, 10);
  const currentJob = useSelector(selectCurrentJob);
  const [doesFormHaveErrors, setDoesFormHaveErrors] = useState(false);
  const [expensesValues, setExpensesValues] = useState<ExpenseValues[]>([]);
  const [clearExpenses, setClearExpenses] = useState(false);
  const refExpensesValues = useRef(expensesValues);
  const handleFormikValues = (value: ExpenseValues) => {
    const indexOfPrevValue = refExpensesValues.current.findIndex(
      (PrevValue) => PrevValue.id === value.id,
    );

    if (indexOfPrevValue === -1) {
      setExpensesValues([...refExpensesValues.current, value]);
      return;
    }

    const oldValues = [...refExpensesValues.current];
    oldValues[indexOfPrevValue] = value;
    setExpensesValues(oldValues);
  };
  const [expenses, setExpenses] = useState([
    <ExpenseForm
      uniqueId={uuid()}
      key={uuid()}
      handleFormikValues={handleFormikValues}
    />,
  ]);
  const classes = useStyles();

  useEffect(() => {
    refExpensesValues.current = expensesValues;
  });

  useEffect(() => {
    if (expensesValues.some((value) => value.errors)) {
      setDoesFormHaveErrors(true);
    }

    if (!expensesValues.some((value) => value.errors)) {
      setDoesFormHaveErrors(false);
    }
  }, [expensesValues]);

  useEffect(() => {
    if (isOpen) {
      setClearExpenses(true);
    }

    if (!isOpen && clearExpenses) {
      setExpenses([
        <ExpenseForm
          uniqueId={uuid()}
          key={uuid()}
          handleFormikValues={handleFormikValues}
        />,
      ]);
      setExpensesValues([]);
      setClearExpenses(false);
    }
  }, [isOpen, clearExpenses]);

  const handleClickAddExpense = () =>
    setExpenses((expense) => [
      ...expense,
      <ExpenseForm
        uniqueId={uuid()}
        key={uuid()}
        handleFormikValues={handleFormikValues}
      />,
    ]);

  const handleClickAddExpenses = () => {
    if (expensesValues.length > 0) {
      expensesValues.forEach(({ file, values: { description } }) => {
        dispatch(
          createJobExpense(
            {
              description,
              jobId,
              date: new Date(),
            },
            file,
            undefined,
            jobId,
            initialJobDetailsQuery,
          ),
        );
      });
      onToggle();
    }
  };

  return (
    <GlobalModal
      customFooter={
        <Box display="flex" flexDirection="column" padding="0px 24px 24px 24px">
          <Button
            className={classes.addNewExpensesButton}
            variant="outlined"
            onClick={handleClickAddExpense}
          >
            Add New Job Site Photo Add New Job Site Photo
          </Button>
          <Divider className={classes.divider} />
          <Button
            className={classes.addExpensesButton}
            disabled={doesFormHaveErrors}
            variant="contained"
            onClick={handleClickAddExpenses}
          >
            Save Job Site Photo Save Job Site Photo
          </Button>
        </Box>
      }
      open={isOpen}
      title="Add New Job Site Photo"
      showActionButtons={false}
      onClickClose={onToggle}
    >
      <Box padding="2px 24px 0px 24px">
        <Typography className={classes.header} variant="h5">
          Job Id {currentJob?.jobId || "--"}
        </Typography>
        {expenses}
      </Box>
    </GlobalModal>
  );
}
