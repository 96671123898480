import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { DateRange } from "materialui-daterange-picker";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteIcon from "@material-ui/icons/Delete";

import { cloneDeep } from "lodash";
import { CustomPagination as Pagination } from "components/Pagination";
import { DropdownPagination } from "components/DropdownPagination";
import { RangeDatePicker } from "components/RangeDatePicker";
import { TableCustom } from "components/table/TableCustom";
import { UploadStatus } from "components/table/UploadStatus";
import {
  selectCount,
  selectLimit,
  selectIsLoading,
  getAllDownladJobs,
  selectDownloadJobs,
} from "features/downloadJobs/downloadJobsSlice";
//import { error } from "features/error/errorSlice";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { downloadJobsService } from "@dwo/shared/dist/services/downloadJobsService";
import { DEFAULT_LIMIT } from "utils/sharedUtils";
import { getTotalPages } from "utils/timesheetsManagementUtils";
import { rowSelectOptions } from "utils/tableUtils";
import { timesheetFileStatuses } from "utils/downladTimesheetsUtils";
import {
  downloadJobsColumns,
  getDownloadJobsRows,
} from "utils/downloadJobsUtils";
import { DownloadFileStatusModel } from "@dwo/shared/src/models/fileDownloadStatusModel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    caption: {
      fontSize: "16px",
      color: theme.palette.grey[500],
      fontWeight: 400,
    },
    customButton: {
      width: "100%",
    },
    header: {
      fontSize: "24px",
      color: theme.palette.primary.main,
      fontWeight: 400,
      marginBottom: "16px",
    },
    historyFiltersContainer: {
      justifyContent: "flex-end",

      [theme.breakpoints.down("xs")]: {
        justifyContent: "flex-start",
      },
    },
    paginationContainer: {
      borderRadius: "2px",
      margin: "0 24px 24px",
    },
    paper: {
      backgroundColor: "white",
      margin: "0 24px 24px 24px",
      padding: "24px",
      borderRadius: 0,
      position: "relative",
    },
    rowSelectLabel: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
    },
    statusText: {
      fontSize: "14px",
      fontWeight: 600,
      justifyContent: "center",
    },
  }),
);

let intervalId: NodeJS.Timeout;

export function DownloadJobs() {
  const classes = useStyles();
  const [customDateRange, setCustomDateRange] = useState<DateRange | undefined>(
    undefined,
  );
  const dispatch = useDispatch();
  const currentCount = useSelector(selectCount);
  const currentLimit = useSelector(selectLimit) || DEFAULT_LIMIT;
  const downloadJobs = useSelector(selectDownloadJobs);
  const isLoading = useSelector(selectIsLoading);
  const [isCustomDiabled, setIsCustomDisabled] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [jobsCSVFile, setJobsCSVFile] = useState<DownloadFileStatusModel>();
  const [
    triggerGetGeneratedFile,
    setTriggerGetGeneratedFile,
  ] = useState<boolean>(false);
  const [query, setQuery] = useState<ServiceOptions>({
    limit: DEFAULT_LIMIT,
    order: [["date", "DESC"]],
  });
  const [rowsPerPage, setRowsPerPage] = useState<string>(
    rowSelectOptions[0].value,
  );
  const [sortingOptions, setSortingOptions] = useState<string[][]>([
    ["date", "DESC"],
  ]);

  useEffect(() => {
    dispatch(getAllDownladJobs(query));
  }, [dispatch, query]);

  useEffect(() => {
    setJobsCSVFile(undefined);
    clearInterval(intervalId);

    if (
      customDateRange &&
      customDateRange.startDate &&
      customDateRange.endDate
    ) {
      setIsCustomDisabled(false);
      return;
    }
    setIsCustomDisabled(true);
  }, [customDateRange]);

  useEffect(() => {
    const getFilesStatus = async () => {
      try {
        const JobCSVStatus = await downloadJobsService.jobsCSVFileStatus();
        setJobsCSVFile(JobCSVStatus?.data?.data);
      } catch (err) {
        console.log("No Jobs CSV File created yet");
      }
    };

    getFilesStatus();

    intervalId = setInterval(() => {
      getFilesStatus();
    }, 15000);
    return () => clearInterval(intervalId);
  }, [triggerGetGeneratedFile]);

  const handleReload = () => {
    setTriggerGetGeneratedFile(!triggerGetGeneratedFile);
  };
  const handleCancel = async () => {
    try {
      await downloadJobsService.cancelGenerateJobsCSVFile();
      setJobsCSVFile(undefined);
      setIsCustomDisabled(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleApplyRangeDate = (dateRange: DateRange) => {
    const startDate = dateRange.startDate;
    const endDate = dateRange.endDate;
    startDate?.setHours(0, 0, 0, 0);
    endDate?.setHours(23, 59, 59, 0);
    const updatedQuery = cloneDeep(query);
    updatedQuery.offset = 0;

    if (startDate && endDate) {
      updatedQuery.where = {
        ...query.where,
        date: { $gte: startDate, $lte: endDate },
      };
    } else {
      if (updatedQuery.where?.date) {
        delete updatedQuery.where.date;
      }
    }

    setPage(1);
    setQuery(updatedQuery);
  };

  const handleCustomRangeDate = (dateRange: DateRange) => {
    const startDate = dateRange.startDate;
    const endDate = dateRange.endDate;
    startDate?.setHours(0, 0, 0, 0);
    endDate?.setHours(23, 59, 59, 0);

    if (startDate && endDate) {
      setCustomDateRange({ startDate, endDate });
    } else {
      setCustomDateRange(undefined);
    }
  };

  const handleClickSort = (sortingValues: string[][]) => {
    setSortingOptions(sortingValues);
    setQuery((prevQuery) => {
      return {
        ...cloneDeep(prevQuery),
        order: sortingValues.length > 0 ? sortingValues : undefined,
      };
    });
  };

  const handleChangePage = (newPage: number) => {
    const updatedQuery = { ...query };
    updatedQuery.offset = (newPage - 1) * currentLimit;
    setPage(newPage);
    setQuery(updatedQuery);
  };

  const handleSelectRowsPerPage = (selectedValue: string) => {
    const updatedQuery = { ...query };
    updatedQuery.offset = 0;
    updatedQuery.limit = Number(selectedValue);

    setPage(1);
    setRowsPerPage(selectedValue);
    setQuery(updatedQuery);
  };

  const downloadFile = async (id: number) => {
    const url = await downloadJobsService.getCSVUrl(id);

    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = url;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const handleClickCancelCustomFile = async () => {
    try {
      await downloadJobsService.cancelGenerateJobsCSVFile();
    } catch (err) {
      console.log("Error", err);
    }
    setIsCustomDisabled(false);
    setJobsCSVFile(undefined);
  };
  const handleClickGenerateCustomFile = async () => {
    try {
      if (
        customDateRange &&
        customDateRange.startDate &&
        customDateRange.endDate
      ) {
        await downloadJobsService.generateJobsCSVFile(
          customDateRange?.startDate,
          customDateRange?.endDate,
        );
        setIsCustomDisabled(true);
      }
    } catch (err) {
      console.log("A file is being generated.");
      /**
       * ! This Error triggers a modal showing two Buttons and a message. It was requested to keep it hidden.
       */
      // dispatch(
      //   error(
      //     {
      //       title: "Could not download CSVs",
      //       message: err.message,
      //     },
      //     () => dispatch(handleClickCustomDownload()),
      //   ),
      // );
    }
    setTriggerGetGeneratedFile(!triggerGetGeneratedFile);
  };

  const handleClickDownloadCustomFile = async () => {
    const CSVFileUrl = await downloadJobsService.downloadJobsCSVFile();
    window.open(CSVFileUrl.data.url);
  };

  return (
    <Fragment>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item sm={6} xs={12}>
            <Box>
              <Typography className={classes.header}>History Jobs</Typography>
            </Box>
          </Grid>

          <Grid item sm={6} xs={12}>
            <Box marginBottom="24px">
              <Grid container spacing={3} justify="flex-end">
                <Grid item>
                  <RangeDatePicker
                    maxDate={new Date()}
                    onClickApply={handleApplyRangeDate}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <TableCustom
              columns={downloadJobsColumns}
              isLoading={isLoading}
              noRowsMessage="No files found"
              rows={getDownloadJobsRows(downloadJobs, downloadFile)}
              sortingOptions={sortingOptions}
              onClickSort={handleClickSort}
            />
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.paginationContainer}>
        <Box padding="12px 24px">
          <Grid alignItems="center" container spacing={4}>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Box display="flex" justifyContent="center">
                <Pagination
                  id="usersManagement-pagination"
                  isDisabled={isLoading || currentCount === 0}
                  page={page}
                  totalPages={getTotalPages(currentCount, currentLimit)}
                  onChangePage={handleChangePage}
                />
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Box marginRight="8px">
                  <Typography
                    variant="caption"
                    className={classes.rowSelectLabel}
                  >
                    Rows per page:
                  </Typography>
                </Box>
                <DropdownPagination
                  id="rowsDropdown"
                  isDisabled={isLoading || currentCount === 0}
                  options={rowSelectOptions}
                  selectedValue={rowsPerPage}
                  onSelectOption={handleSelectRowsPerPage}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <Paper className={classes.paper}>
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            <Typography className={classes.header}>Custom Export</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.caption}>
              You can search for a specific date to download a “CSV” file
            </Typography>
          </Grid>

          <Grid item sm={12}>
            <Grid container spacing={2}>
              <Grid item sm={4} xs={12}>
                <RangeDatePicker
                  upAndRightLocation
                  maxDate={new Date()}
                  onClickApply={handleCustomRangeDate}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  className={classes.customButton}
                  disabled={isCustomDiabled}
                  onClick={handleClickGenerateCustomFile}
                >
                  Generate File
                </Button>
              </Grid>
              <Grid item xs={4}>
                <>
                  <Button
                    variant="contained"
                    className={classes.customButton}
                    disabled={
                      jobsCSVFile?.status !== timesheetFileStatuses.SUCCESSFUL
                    }
                    onClick={handleClickDownloadCustomFile}
                  >
                    Download File
                  </Button>
                </>
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.statusText}>
                  Last Status:
                </Typography>
                <UploadStatus
                  status={jobsCSVFile?.status || "New File"}
                  hideStatusText={false}
                />
              </Grid>
              <Grid item xs={2}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    columnGap: 10,
                  }}
                >
                  {jobsCSVFile?.status === timesheetFileStatuses.GENERATING && (
                    <Button variant="outlined" onClick={handleCancel}>
                      <DeleteIcon />
                    </Button>
                  )}
                  <Button variant="outlined" onClick={handleReload}>
                    <RefreshIcon />
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
}
